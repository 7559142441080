import { defineComponent, toRef } from 'vue';
import { clone } from 'lodash-es';
import { globalState } from '@/globalStore';
// import { api } from '@/composition/api'
import Topbar from '@/components/ui/Topbar.vue';
import DataTable from '@/components/datatable/DataTable.vue';
import TableHead from '@/components/datatable/TableHead.vue';
import TableRow from '@/components/datatable/TableRow.vue';
import TablePagination from '@/components/datatable/TablePagination.vue';
import ThItem from '@/components/datatable/ThItem.vue';
import TdItem from '@/components/datatable/TdItem.vue';
import ThOrderingIcon from '@/components/datatable/ThOrderingIcon.vue';
import { responsiveDate } from '@/components/datatable/contrib';
import { api } from '@/composition/api';
export default defineComponent({
    components: {
        Topbar,
        DataTable,
        TableHead,
        ThItem,
        TdItem,
        TableRow,
        TablePagination,
        ThOrderingIcon,
    },
    setup() {
        const isInitialized = toRef(globalState.artists, 'initialized');
        const eventsApi = clone(api);
        if (!isInitialized.value) {
            globalState.artists.initialized = true;
            console.log('artists initialized!');
        }
        eventsApi.defaults.baseURL =
            process.env.VUE_APP_API_HOST + '/api/panopticon';
        const remoteConfig = {
            mode: 'remote',
            axiosInstance: eventsApi,
            dataModel: 'events',
            itemsPerPage: 10,
            searchFields: ['name', 'slug'],
            filters: [
                {
                    property: 'isVip',
                    type: 'boolean',
                },
            ],
        };
        return {
            isInitialized,
            artists: globalState.events.data,
            api: eventsApi,
            remoteConfig,
            responsiveDate,
        };
    },
});
